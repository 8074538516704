<template>
  <div class="card form-card checkout collapse show">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('forms.invite.title') }}</h5>
    </div>
    <div class="sender_name collapse show">
      <div class="card-block task-details ">
        <invite-send-form></invite-send-form>
      </div>
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  name: 'InviteSendPage',
  components: {
    'invite-send-form': defineAsyncComponent(() => import('@/application/components/forms/InviteSendForm.vue')),
  },
  setup() {},
}
</script>
